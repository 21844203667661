@import "./config.scss";
@import "material.scss";
@import "./btn.scss";
@import "datepicker.scss";
@import "./form.scss";  
@import "./modulos.scss";  
@import "./page.scss";  
@import "./galeria.scss";    
@import "./modais.scss";   
@import "./footer.scss";   
@import "./template.scss";    
@import "_animate.scss"; 

html, body { height: 100%; }
body{
    font-family: map-get($fonts,one);
    font-size: 12px;
    font-weight: 400;
    color: #616161;
}
h1,h2,h3{
    font-family: map-get($fonts,one);
}
figure,ul,p{
    margin: 0;
}
input,button{
    outline: none !important;
}
.linha-white{
    height: 5px;
    width: 100%;
    background: #FFF;
    display: table;
    margin: 20px 0;
}
/** grid **/
.wp-layout-grid{
    display: flex !important;
    overflow: auto;
    //margin: -20px -20px -20px -30px;
    margin: -20px -10px -20px -20px; 
    padding: 20px 0 0 20px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    scroll-behavior: smooth; 
}
.wp-layout-item{
    margin-right: 20px; 
    -webkit-flex-basis: 290px;
    -ms-flex-preferred-size: 290px;
    flex-basis: 290px; 
    flex: 0 0 290px;  
    margin-bottom: 5px;
    -webkit-box-flex: 0;
}
.wp-layout-grid::-webkit-scrollbar {
    background-color: transparent !important;
}
.message-default{
    text-align: center;
    font-size: 18px; 
}
.content-bottom-main h3{
    color: #616161;
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 600;
}
.p-margin p{
    margin: 0 0 10px 0;
}
a,button{
    outline: none !important;
}

@media (min-width: 1200px){
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1280px;
    }
}

@media(max-width: 767px){

    header .lista-actions .text{
        display: none !important;
    }

}
@media(max-width: 480px){


    .hide-mobile{
        display: none !important;
    }
    /*html,body{
        overflow-x:hidden !important;
        -webkit-overflow-x:hidden !important;
        -ms-overflow-x:hidden !important;
        -moz-overflow-x:hidden !important;
    }*/
    
}