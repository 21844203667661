@import "config.scss";

.center-flex{
    display: flex;
    align-items: center;
}
.module h2{
    color: #353535;
    font-size: 26px;
    font-weight: 700;
}
.module h3{
    color: #353535;
    font-size: 18px;
    font-weight: 200;
    margin: 8px 0 0 0;
}
.module-content{
    position: relative;
}
.slide-navigate-item{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 100%;
    position: absolute;
    top: 32%;
    cursor: pointer;
}
.slide-navigate-item.prev{
    left: -20px;
}
.slide-navigate-item.next{
    right: -20px;
}
.slide-navigate-item img{
    display: table;
    margin: 0 auto;
}
.slide-navigate-item.template-2.next{
    right: 0;
}
.slide-navigate-item.template-2.prev{
    left: 0;
}
.slide-navigate-item.template-2{
    width: 45px;
    height: 45px;
}
.slide-navigate-item.template-2 img{
    width: 7px;
}
.center{
    display: table;
    margin: 0 auto;
}
.z-index-1{
    position: relative;
    z-index: 1;
    display: block;
}
.z-index-2{
    position: relative;
    z-index: 2;
    display: block;
}
.page{
    padding: 50px 0 100px 0;
}
.page-title{
    margin: 0 0 20px 0;
}
.page h1{
    font-size: 30px;
    font-family: map-get($fonts,two);
    font-weight: 800;
}
.box-sombra{
    background: #FFF;
    box-shadow: 5px 5px 20px 0 #d4d4d4;
    padding: 20px;
}
strong{
    font-weight: 700;
}
a{
    text-decoration: none !important;
    color: #353535;
}
.title-h3{
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 10px 0;
}
.text-custom{
    font-size: 13px;
    line-height: 20px;
}
.linha{
    height: 1px;
    width: 100%;
    display: table;
    margin: 15px 0;
    background: #eee;
}
/** default list contact **/
.list-contato .default-list{
    display: flex;
    align-content: center;
    padding: 15px 0;
}
.list-contato .default-list.page-contato-telefone .icon span{
    transform: rotate(80deg);
}
.list-contato .default-list .icon{
    margin: 0 15px 0 0;
}
.list-contato .default-list .icon span{
    font-size: 40px;
    color: map-get($colors,five);
}
.list-contato .default-list .title{
    display: table;
    font-weight: 800;
    line-height: 15px; 
}
.list-contato .default-list a{
    font-size: 18px;
    text-decoration: none !important;
}
/** select 2 **/
.select2-container .select2-selection--single{
    height: 45px;
    outline: none !important;
    padding: 7px;
    background: #FFF !important;
    border-radius: 5px;
    border: 1px solid map-get($colors,one); 
    max-width: 100% !important;
    width: 100% !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b{
    top: 80%;
    margin-left: -10px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
    color: #000;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    top: 2px;
    max-width: 100% !important;
}
.select2,.select2-hidden-accessible{
    width: 100% !important;
    max-width: 100% !important;
    table-layout: fixed;
    display: table;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice{
    background-color: map-get($colors,one) !important;
    color: #FFF !important;
    text-transform: uppercase;
    font-size: 14px;
    padding: 7px 15px !important;
}
.select2-container--default .select2-results__option--highlighted[aria-selected]{
    background: map-get($colors,two) !important;
}
.select2-results__option--selectable{
    padding: 15px 10px;
    font-size: 14px;
}
.grecaptcha-badge{
    display: none !important;
}
.page-frota .descricao article ul li{
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
}
.page-frota .descricao article ul li:before{
    content: "";
    background: map-get($colors,seven);
    width: 15px;
    height: 15px;  
    border-radius: 100%;
    margin: 0 10px 0 0;  
    margin: 0 10px 0 0;
    display: table;
}
.page-title h2{
    font-size: 18px;
}
.title-h3{
    font-size: 24px;
    font-weight: 600;
    margin: 30px 0 20px 0;
    color: map-get($colors,one);
}
/* Tooltip container */
.tooltip-custom{  
    position: relative;
    display: inline-block;  
}
/* Tooltip text */
.tooltip-custom .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    display: table;
}
/* Tooltip arrow */
.tooltip-custom .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-custom:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
.tooltip-bottom {
    top: 135% !important;
    left: 50% !important;
    margin-left: -60px !important;
}
.tooltip-bottom::after {
    content: "" !important;
    position: absolute !important;
    bottom: 100% !important;
    left: 50% !important;
    margin-left: -5px !important;
    border-width: 5px !important;
    border-style: solid !important;
    border-color: transparent transparent #555 transparent !important;
    top: -10px !important;
}
.card-item{
    padding: 0.8rem 1.3rem;
    position: relative;
    margin-right: 8px;
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
}
.card-item .header{
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
}
.card-item .header span{
    font-weight: bold;
    color: map-get($colors,seven);
    flex: 1;
    font-size: 16px;
}
.card-item .header i{
    font-size: 18px;
    color: map-get($colors,two);
}
.linha-grey{
    background: #f9f9f9;
    height: 5px;
    width: 100%;
    display: table;
    margin: 20px 0;
}
.modulo.banner-slider .slick-dots{
    bottom: 15px;
}
.modulo.banner-slider .slick-dots li button:before{
    color: #FFF;
    font-size: 12px;
}
.modulo.banner-slider .slick-dotted.slick-slider{
    margin-bottom: 0 !important;
}
.product-item .tariff{
    color: #FFF;
}

@media(max-width: 959px){

    header .menu-desktop{
        display: none !important;
    }
    header .logo{
        margin: 0 !important;
    }
    header .logo img{
        width: 200px;
    }
    header .topo-partial .li-item,
    header .topo-partial .divisor-circle
    {
        display: none !important;
    }


}
@media(max-width: 767px){

    .slide-navigate-item.prev{
        left: 0;
    }
    .slide-navigate-item.next{
        right: 0;
    }
    .slide-navigate-item{
        width: 45px;
        height: 45px;
        top: 40%;
    }
    .slide-navigate-item img{
        width: 8px;
    }
    .slick-padding-mobile .slick-list{
        padding: 10px 20px 10px 10px !important;
    }

} 
